(function () {
	'use strict';
	angular
		.module('app')
		.component('documents', {
			templateUrl: 'app/components/documents/documents.html',
			controller: CustomerApplicationDocumentController
		});

	CustomerApplicationDocumentController.$inject = ['$uibModal', 'user', 'url', '$stateParams', '_', 'globalService', '$rootScope'];

	function CustomerApplicationDocumentController($uibModal, $user, $url, $stateParams, _, $globalService, $rootScope) {
		var vm = this;
		swal('Info','Write title before selecting the photo','info');
		vm.loadingResumen = true;
		vm.dzOptions = {
			url: $url.getApiUrl('customer_applications') + '/' + $stateParams.app_id + '/documents?token='+$user.getCurrentUser().token,
			paramName: 'document',
			headers: { token: $user.getCurrentUser().token },
			maxFilesize: '10',
			addRemoveLinks: true,
		};

		vm.dzCallbacks = {
			'sending': function(file, xhr, formData) {
				formData.append('title', vm.title);
				console.log(file,xhr, formData)
			},
			'addedfile': function (file, formDAta) {
				// console.log(file, formDAta)
			},
			'success': function (file, response) {
				vm.loadingResumen = false;
				swal('Exito', response.message, 'success');
				vm.$onInit();
				vm.newFile = undefined;
				vm.title = undefined;
			},
		};

		vm.$onInit = function () {
			$rootScope.$emit('uploadBreadCrum', { current: 'Customer Application Docuemnts', before: 'Home' });
			$globalService.documents.query({ app_id: $stateParams.app_id }).$promise
			.then(function (res) {
				vm.documents = res;
			})
		}

		vm.deleteDocument = function (params) {
			swal({
        title: 'Are you Sure?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        showLoaderOnConfirm: true,
        preConfirm: function () {
          return new Promise(function (resolve, reject) {
						$globalService.documents.delete({app_id: params.crmid, id: params.id}, function(res){
							resolve(true);
						}, function () {
							vm.send = false;
							reject('Ha ocurrido un error');
						});
          });
        },
        allowOutsideClick: false,
      }).then(function (res) {
				if(res){
					swal('Exito','Document Delete succesfully','success');
					vm.$onInit();
				}
			});
			
		}

		$rootScope.$on('loadDatabases', function (evt, data) {
			vm.$onInit();
		})

	}


})();
