(function () {
  'use strict';
  angular
  .module('app')
  .component('availability', {
    templateUrl: 'app/components/availability/availability.html',
    controller: AvailabilityController,
    bindings: {
      resolve: '<',
      close: '&',
      dismiss: '&',
    }
  });

  AvailabilityController.$inject = ['$rootScope','globalService','user'];

  function AvailabilityController($rootScope, $globalService, $user) {
    var vm = this;
    vm.loading = false;

    vm.$onInit = function(){
      vm.currentUser = $user.getCurrentUser();
    }

    function getAvailabilityInfo(currentApplication, form_type){
      if(currentApplication){
        $globalService.seachSign({crmid: currentApplication.id, form_type: currentApplication.availability_form_type})
        .then(function(res){
          vm.sign = res;
          vm.signed_forms = _.map(res.forms_signeds, function(data){
            data.pretty_signed_form = capitalize(data.signed_form.split('_').join(' '));
            return data;
          });
          vm.loading = false;
        }, function(err){
          vm.sign = err.data;
        })
      }
    }

    vm.getAvailabilityLink = function(id){
      vm.loading = true;
      $globalService.seachSignFile({crmid: vm.currentApplication.id, signed_form_id: id})
      .then(function(res){
        const url = URL.createObjectURL(res.data);
        const a = document.createElement('a');
        a.download = vm.currentApplication.main_applicant.phone + '_'+ vm.currentApplication.id + '.pdf';
        a.href = url;
        a.target = '_self';
        a.click();
        vm.loading = false;
      }, function(err){
        vm.signFile = err.data;
        vm.loading = true;
      })
    }

    vm.importSSN = function(signId, crmId) {
      vm.loading = true;
      $globalService.importSSN({sign_id: signId, crm_id: crmId})
      .then(function(res){
        vm.loading = false;
        loadCustomerApplications();
        swal('Success', res.message, 'success');
      }, function(err){
        vm.importedSSN = err.data;
        vm.loading = false;
        swal('Error', err.data.message, 'error');
      });
    }

    $rootScope.$on('setCurrentApplicant', function (event, data) {
      vm.currentApplication = data.current;
      vm.allCompanies = data.allCompanies;
      getAvailabilityInfo(data.current)
    })

    function loadCustomerApplications(){
      vm.loading = true;
      $globalService.resApplications.get({id: vm.currentApplication.id}).$promise
      .then(function (res) {
        vm.loading = false;
        vm.currentApplication = res;
        vm.showCurrentApplication = true;

        vm.companySelected = _.find(vm.allCompanies, { id: vm.currentApplication.insurance_data.company.id });
        vm.productSelected = _.find(vm.companySelected.products, { id: vm.currentApplication.insurance_data.company_product.id });

        vm.typePlanSelected = _.find(vm.productSelected.company_product_type, { id: vm.currentApplication.insurance_data.company_product_type.id });
        vm.planSelected = _.find(vm.typePlanSelected.company_product_plans, { id: vm.currentApplication.insurance_data.company_product_plan.id });

        $rootScope.$emit('setCurrentApplicant', {
          current: vm.currentApplication,
          insurances: vm.insurances,
          allCompanies: vm.allCompanies,
          companySelected: vm.companySelected,
          productSelected: vm.productSelected,
          typePlanSelected: vm.typePlanSelected,
          planSelected: vm.planSelected,
          applicationTypeSelected: vm.applicationTypeSelected
        });
      });
    }
    

    function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    }

  }

})();
