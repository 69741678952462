(function(){
  'use strict';
  angular
    .module('app')
    .component('sendCustomerApps', {
      templateUrl: 'app/components/home/send_customer_apps.html',
      controller: SendCustomerAppsController,
      bindings: {
        resolve: '<',
        close: '&',
        dismiss: '&',
      }
    });

  SendCustomerAppsController.$inject = ['user', '$rootScope', '$state', '_', 'globalService','$uibModal','$window'];

  function SendCustomerAppsController($user, $rootScope, $state, _,$globalService, $uibModal, $window) {
    var vm = this;
    vm.loadingRequests = false;
    vm.currentUser = $user.getCurrentUser();
    vm.showCurrentApplication = false;
    vm.loading = false;
    vm.requireDocuments = [];
    vm.listRequireDocuments = ['Income','Social Security Number', 'Status Migratory', 'Relocation','Lost Cover', 'Others'];
    vm.tabPanel = {
      personalData: true,
      insuranceData: false,
      billingInformation: false,
      requiredData: false,
    }
    vm.statuses = [
      {key: 0, value: "Enviada"},
      {key: 1, value: "En Proceso"},
      {key: 2, value: "Aprobada"},
      {key: 3, value: "Conflicto de Agentes"},
      {key: 4, value: "Activa"},
      {key: 5, value: "Baja"},
      {key: 6, value: "Pending Link"},
      {key: 7, value: "Pending Note"},
      {key: 8, value: "SSN Pending"},
    ]

    vm.$onInit = function(){
      vm.currentPage = 1;
      vm.per = 20;
      vm.params = {};
      $rootScope.$emit('uploadBreadCrum', {current: 'Dashboard', before: 'Home'});
      
      vm.withNpn = true;
      vm.data = vm.resolve.data;
      vm.currentPage = vm.data.current_page;
      vm.send = false;
      vm.data.applications = vm.resolve.data.data;
      vm.statusSelected = _.findWhere(vm.statuses, {key: vm.resolve.status});
      vm.params.status = vm.statusSelected.key;
      vm.loading = false;
      vm.insurancePeriods = [moment().format('YYYY'), moment().add(1, 'years').format('YYYY')];
      
      $globalService.companies.query(function(res){
        vm.insurances = res;
      })

      $globalService.users.query({lite: true, use_npn: true}).$promise
      .then(function(res){
        vm.usersNpns = res;
      })

      $globalService.applicationProcessedByManagers()
      .then(function(res){
        vm.total_my_applications = res;
      })
      $globalService.getAllAgents.query({by_status: vm.params.status}).$promise
      .then(function(res){
        vm.agents = res;
      })

      $globalService.companies.query().$promise.then(function name(res) {
        vm.companies = res;  
      }, function(err) {
        vm.send = false;
      })
      $globalService.users.query({'user_type_id': '1,2,4'}).$promise
      .then(function(res){
        vm.users = _.map(res, function(data){
          data.slug_name = data.full_name.split(' ').join('-');
          return data;
        });
        vm.loading = false;
      })
    }

    vm.setAgents = function(){
      vm.params.user_id = _.pluck(vm.agentsSelected, 'id');
      console.log(vm.params)
    }

    vm.showChangeLife = function (application) {
      var modal = $uibModal.open({
        animation: true,
        component: 'lifechanges',
        windowClass: 'show',
        backdrop: 'static',
        size: 'lg',
        resolve: {
          currentApplication: function () {
            return application;
          }
        }
      });
    }

    vm.pageChanged = function () {
      vm.send = true;
      vm.loading = true;
      vm.currentPage = vm.data.current_page;
      vm.params.page = vm.currentPage;
      vm.params.per = vm.per;
      $globalService.applicationsDashboard(vm.params)
        .then(function (res) {
          vm.send = false;
          vm.data.applications = res.data;
          vm.loading = false;
        })
    };


    vm.consultApplicationsAll = function(){
      vm.data.applications = [];
      if(!vm.withNpn){
        vm.params.user_npn = null;
        vm.userNpnSelected = undefined;
      }
      $globalService.applicationsDashboard(vm.params)
      .then(function(res){
        vm.data = res;
        vm.currentPage = vm.data.current_page;
        vm.send = false;
        vm.data.applications = res.data;
        vm.loading = false;
      })
    }

    vm.selectAllPage = function(){
      vm.data.applications.forEach(function(data){
        data.selected = !data.selected;
      });
    }

    vm.sendCustomerApps = function(){
      
      if(_.where(vm.data.applications, {selected: true}).length == 0){
        swal('Error', 'You must be select some apps', 'error');
        return;
      }
      if(!vm.usersSelected){
        swal('Error', 'You must be select an user to send apps', 'error');
        return;
      }
      vm.loading = true;
      var apps = _.pluck(_.where(vm.data.applications, {selected: true}), 'id');
      var data = {
        customer_apps_ids: apps,
        user_id: vm.usersSelected.id
      }
      $globalService.sendCustomerAppsToUsers(data)
      .then(function(res){
        vm.loading = false;
        swal('Exito', res.message, 'success');
      }, function(err){
        vm.loading = false;
        swal('Error', err.data.message, 'danger');
      });
    }

    $rootScope.$on('searchApp', function(evt,data){
      vm.params = data.params;
      findApplication()
    })
    
  }


})();
