(function () {
  'use strict';
  angular
    .module('app')
    .component('ssnPendingButton', {
      bindings: {
        application: '<',  // Recibe el parámetro application
        permission: '<'       // Recibe el parámetro permiso
      },
      templateUrl: 'app/components/featuresComponents/ssnPending/ssnPendingButton.html',
      controller: function (globalService, $rootScope) {
        var vm = this;

        vm.$onInit = function() {
          if (typeof vm.permission === 'undefined') {
            vm.permission = true;
          }
          vm.application = vm.application;
        };
    

        vm.setSsnPending = function () {
          swal({
            title: 'Are you sure to set SSN Pending?',
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Accept',
            showLoaderOnConfirm: true,
            preConfirm: function () {
              return new Promise(function (resolve, reject) {
                globalService.setSsnPending(vm.application)
                  .then(function (res) {
                    resolve(res);
                  }, function (err) {
                    Swal('Wait', err.data.message, 'warning');
                  });
              });
            },
            allowOutsideClick: false,
          }).then(function (res) {
            if (res.value) {
              swal('Exito', res.value.message, 'success');
              $rootScope.$emit('initAllAplications');
            }
          });
        };
      },
    })
})();
